import InstantSearch from 'vue-instantsearch/vue3/es'
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'

export default defineNuxtPlugin((nuxtApp) => {
  // Register Vue InstantSearch
  nuxtApp.vueApp.use(InstantSearch)

  // Use environment variables directly if available (for development)
  const meilisearchUrl =
    useNuxtApp().$config.public.meilisearchUrl || process.env.MEILISEARCH_URL
  const meilisearchKey =
    useNuxtApp().$config.public.meilisearchKey || process.env.MEILISEARCH_KEY

  // Log Meilisearch configuration for debugging
  console.log('Meilisearch plugin loaded')
  console.log('Meilisearch URL being used:', meilisearchUrl)
  console.log(
    'Meilisearch Key being used:',
    meilisearchKey ? 'is set' : 'not set'
  )

  // Create Meilisearch client if configuration is available
  let searchClient = null

  if (meilisearchUrl) {
    try {
      searchClient = instantMeiliSearch(meilisearchUrl, meilisearchKey, {
        primaryKey: 'id',
        finitePagination: true,
        placeholderSearch: false,
        keepZeroFacets: false,
      }).searchClient

      console.log('Global Meilisearch client initialized successfully')
    } catch (error) {
      console.error('Error initializing global Meilisearch client:', error)
    }
  }

  // Provide Meilisearch client to the application
  nuxtApp.provide('meilisearch', {
    searchClient,
    isConfigured: !!meilisearchUrl,
  })
})
