export default class Endpoints {
  public static readonly SPACES: string = '/api/v1/spaces'
  public static readonly USERS: string = '/api/v1/users'
  public static readonly ADDRESSES: string = '/api/v1/address'
  public static readonly AUTHENTICATION: string = '/api/v1/authentication'
  public static readonly SEARCH: string = '/api/v1/search'
  public static readonly MAIL: string = '/api/v1/mail'
  public static readonly FAVORITES: string = '/api/v1/favorites'
  public static readonly QUOTES: string = '/api/v1/quotes'
  public static readonly REVIEWS: string = '/api/v1/reviews'
  public static readonly REPORTS: string = '/api/v1/reports'
  public static readonly NEWSLETTER: string = '/api/v1/newsletters'
  public static readonly ARTICLES: string = '/api/v1/blog/articles'
  public static readonly PROVIDER_SERVICE_TYPES: string =
    '/api/v1/service-types'

  public static readonly PROVIDERS: string = '/api/v1/providers'
}
