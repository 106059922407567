import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_zl4DJTOXR_P7Za5mHcxtiE0qF41aOcNQSDoWWkKmmac from "/app/node_modules/@dargmuesli/nuxt-cookie-control/dist/runtime/plugin.mjs";
import vmoney_CQIDcPHOviK_HCeqZMfigentXIFV1u5Q83Ur3Ii2EWE from "/app/plugins/vmoney.js";
import meilisearch_4h6ViNWhFz74siXa_jKEA6IeN8HN3VGpeXngsOhgMNc from "/app/plugins/meilisearch.js";
import facebooksdk_client_64ffSfJUjKaq52IlUe_d02NqbHHpHUfD_9lZK3wj8Fw from "/app/plugins/facebooksdk.client.js";
import googlesignin_client_V699rA7_NQGWsKf48nwlSwo60sSS2GdHDaFweYPNvFo from "/app/plugins/googlesignin.client.js";
export default [
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  plugin_zl4DJTOXR_P7Za5mHcxtiE0qF41aOcNQSDoWWkKmmac,
  vmoney_CQIDcPHOviK_HCeqZMfigentXIFV1u5Q83Ur3Ii2EWE,
  meilisearch_4h6ViNWhFz74siXa_jKEA6IeN8HN3VGpeXngsOhgMNc,
  facebooksdk_client_64ffSfJUjKaq52IlUe_d02NqbHHpHUfD_9lZK3wj8Fw,
  googlesignin_client_V699rA7_NQGWsKf48nwlSwo60sSS2GdHDaFweYPNvFo
]