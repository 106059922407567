
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexXDrvC4X9cQh98rUTaKV7n9SAzKfJcTLnbJGv9g_YdesMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as indexsbvw5WFLIgWuiP0inbCrdp8OW0p1DMumuz0udiF8ZYEMeta } from "/app/pages/site/index.vue?macro=true";
import { default as articleTSwZNd5F2rSMG3FYvH6lQ48xeWAWK4ctgubmuoboCs0Meta } from "/app/pages/blog/article.vue?macro=true";
import { default as contact4R13SJ0t6jQw5tC3K8dCh37dPstl_45VfnAY_45BAIREyWMMeta } from "/app/pages/site/contact.vue?macro=true";
import { default as privacyfgclljfK1vzIIttPw7Q0dGkY07_bFLNmmRuML4_45yUAIMeta } from "/app/pages/site/privacy.vue?macro=true";
import { default as index_45F_uAQC8N4LTDgd1cq42C2ftJy11F_99xjd_45gOWaGcoMeta } from "/app/pages/dashboard/index.vue?macro=true";
import { default as quotes1t2Q3JinzN1G2Ntsg0qDXG7lQYpDdnpph4FIJLYC8OwMeta } from "/app/pages/dashboard/quotes.vue?macro=true";
import { default as reviewsihoE5WL_45bfH7El4veuG_S1CHeTrB_92AYG_cI4lpxl0Meta } from "/app/pages/dashboard/reviews.vue?macro=true";
import { default as settingsdbGcSVbrN6nxlDN_45PE7jX29jsE03Vh_dTSCKrsal9FwMeta } from "/app/pages/dashboard/settings.vue?macro=true";
import { default as searchXQ02_2RmJXrsPIspAOIi_CfYiTSz1Evb5HQLbHSBBeUMeta } from "/app/pages/site/spaces/search.vue?macro=true";
import { default as favoritesZde1Ies0_45nRSDUdgx2KY7_45_45_451_mJdPtnHv1gWr_NBHEMeta } from "/app/pages/dashboard/favorites.vue?macro=true";
import { default as fbdatadeletionYi_45pO6iyVTnGZxQHM0t5icte0qovRSpmEVeloCDgZGMMeta } from "/app/pages/site/fbdatadeletion.vue?macro=true";
import { default as detailsz_GFXp4my5FTOo6J4TWwsQom7u8FIBL9CRQpUJrsOXkMeta } from "/app/pages/site/spaces/details.vue?macro=true";
import { default as loginajnHrA2vjZ4XnqDCIBMC_45XHoBZB8hN6aofvPDVqlmssMeta } from "/app/pages/authentication/login.vue?macro=true";
import { default as newr_cS_45GVCNY2OpouDXY6UGY90xgMhhg_45w5yNpVmYcqOAMeta } from "/app/pages/dashboard/spaces/new.vue?macro=true";
import { default as searchSdTAISSlFy_BtXJMQZkNtfX6MZcYZ97srSdfGf_UTQkMeta } from "/app/pages/site/providers/search.vue?macro=true";
import { default as details7obTvRyzuEtk3Y_45Fa3oZLDe4DMFiyL8RPioN86UAOnUMeta } from "/app/pages/site/providers/details.vue?macro=true";
import { default as register2su_bjvCCNq30V8QEz4nbl_45vKMl0u974THKgWxEvZ_45UMeta } from "/app/pages/authentication/register.vue?macro=true";
import { default as newi7EGS8UMvE39k_SbjT65NRSYn30C2KjLwX8avyItSo0Meta } from "/app/pages/dashboard/providers/new.vue?macro=true";
import { default as UserHomeJGzg4Yh_45t3b8ItZ2CaAToFGeq1wyvKPOblG4GPMYxskMeta } from "/app/pages/dashboard/user/UserHome.vue?macro=true";
import { default as AdminHomeKnLkW_EOEb_4al_45jDRitlSa_zSKVsrn7F6tPiI_4595HYMeta } from "/app/pages/dashboard/admin/AdminHome.vue?macro=true";
import { default as UserQuotesrHhhi1QCxihgkQeVkyBLCoWp6NGJ5Lflnmmp10gFk2gMeta } from "/app/pages/dashboard/user/UserQuotes.vue?macro=true";
import { default as OwnerHome8WIVFPGclvVGt_AdRg_45q_45KGR0zTBcfLQA7HsrBmInMQMeta } from "/app/pages/dashboard/spaces/OwnerHome.vue?macro=true";
import { default as UserReviewsReZgZxlz36YiQua_Qz_45jFyKtpI_45Epcnuvbxxh_NYe9wMeta } from "/app/pages/dashboard/user/UserReviews.vue?macro=true";
import { default as new_45passwordWnKPfmOtBajrYM1zL3Fe6eS6HDfXcTV66Lm8_XzsL7EMeta } from "/app/pages/authentication/new-password.vue?macro=true";
import { default as owner_45landing2I03EkO9N1xnes7z5WDfJQ_45UZH6Pi2Mq9PP3abCdAKgMeta } from "/app/pages/site/landings/owner-landing.vue?macro=true";
import { default as OwnerQuotes7Ex65RnNmPmCseu2YAJuwIHAcuGdG6dzcKiHKQ1Si2sMeta } from "/app/pages/dashboard/spaces/OwnerQuotes.vue?macro=true";
import { default as UserFavorites_45dlMPJU4B65842zUjh7tz1wUI8xNiiSQs9KvLRvUfEIMeta } from "/app/pages/dashboard/user/UserFavorites.vue?macro=true";
import { default as register_45owneriAdSY2lNHHthx6D142w3mTHD4psEiPZ_regFrs_459ws0Meta } from "/app/pages/authentication/register-owner.vue?macro=true";
import { default as OwnerReviewsiZvFr3a_45X5QNHewUre_4576nCmZetZe91dB4tWIahzc_45gMeta } from "/app/pages/dashboard/spaces/OwnerReviews.vue?macro=true";
import { default as service_45landingCYWtj0l_lhL2EIHPsNnAMa5uAVYZMbSt_45TjyvwL_J3oMeta } from "/app/pages/site/landings/service-landing.vue?macro=true";
import { default as register_45service1bTL5kOA_fcFyv3ioU9tQ9q_45lSYdPK2MPJ4P9Quuz9IMeta } from "/app/pages/authentication/register-service.vue?macro=true";
import { default as SpaceBasicInfoqY5y1g__45wm0_45go_45NYo2KtsSxv9JAuLMc2gfkf2i4fU8Meta } from "/app/pages/dashboard/spaces/SpaceBasicInfo.vue?macro=true";
import { default as SpacePriceInfoj8cSPVlOymjmWaChkjqTLgd_45uUqDLe7MabWwhgAryKwMeta } from "/app/pages/dashboard/spaces/SpacePriceInfo.vue?macro=true";
import { default as SpaceRulesInfowc_45nwMBtAeeFbkB_45YwaRIE9OpJhdChgX_45mZDqmksOrcMeta } from "/app/pages/dashboard/spaces/SpaceRulesInfo.vue?macro=true";
import { default as password_45recoveryKSsRK1skz4QWhp71M5_ive_45vsYd49X68HiYd3ep_Qt0Meta } from "/app/pages/authentication/password-recovery.vue?macro=true";
import { default as ProviderFaqscWyae7_njmmIk24AUmbCXL6_458BgWQPH_XJVL7mQqC40Meta } from "/app/pages/dashboard/providers/ProviderFaqs.vue?macro=true";
import { default as ProviderHomelfaaRmmdwHD2vmvqzQ2zeQOhKjfKe93wAhT4iWR0ExAMeta } from "/app/pages/dashboard/providers/ProviderHome.vue?macro=true";
import { default as SpacePhotosInfotdaCD1xnBWSBteqgqVoAZyUWR0EZH3_45B0YovwSkCjLYMeta } from "/app/pages/dashboard/spaces/SpacePhotosInfo.vue?macro=true";
import { default as SpaceAddressInfoan2uWIQYaGCqXeGeQNFZgztbRWY64E5EHz3TuFjKZiwMeta } from "/app/pages/dashboard/spaces/SpaceAddressInfo.vue?macro=true";
import { default as SpaceContactInfoBwe3jPvHZsRG_rw_pEbj_45I4T2G2Qv_pLSKKtTuO58eEMeta } from "/app/pages/dashboard/spaces/SpaceContactInfo.vue?macro=true";
import { default as SpaceSocialsInfov_45Yia0X2MUUd9WHc_2DEzwIn_45tMYmRLKqMmzUfxWUPcMeta } from "/app/pages/dashboard/spaces/SpaceSocialsInfo.vue?macro=true";
import { default as AdminPendingSpacesUg6XVldwZPvBFYlPZDUhO6p6zxv_GR9YyzxmyAW9TQ4Meta } from "/app/pages/dashboard/admin/AdminPendingSpaces.vue?macro=true";
import { default as ProviderQuotesGaRLP40sCzQhK6tDYvualb_45_n_u6olSNjyf5XvVidiYMeta } from "/app/pages/dashboard/providers/ProviderQuotes.vue?macro=true";
import { default as SpaceServicesInfoZROewVxtRtVg9ieDXUmTGd__459vmI3ks3PkTn9orU0lYMeta } from "/app/pages/dashboard/spaces/SpaceServicesInfo.vue?macro=true";
import { default as ProviderReviews0rX0YKAcGbEDBCvKA62pJGMLKVJ5oGcY1gW_45mtcedogMeta } from "/app/pages/dashboard/providers/ProviderReviews.vue?macro=true";
import { default as space_45claim_45ownership9lmqps_45WyHmEJvB17jJ2vxEfprolTOjeSC8xkG7ueKAMeta } from "/app/pages/authentication/space-claim-ownership.vue?macro=true";
import { default as ProviderTypeInfoSmiodS0tztLRg9gycDW2FyG7qLjxLsonRLTM_50n4UUMeta } from "/app/pages/dashboard/providers/ProviderTypeInfo.vue?macro=true";
import { default as SpaceEventTypesInfoCB_452D51vn8OUdfcBCFevqzM_fKMin4OCgSJlAV_45pkGwMeta } from "/app/pages/dashboard/spaces/SpaceEventTypesInfo.vue?macro=true";
import { default as AdminPendingProvidersNdSm_45yc1K_MVuH6nFEBtXpyoM7IFwymK7Jqy579aVQgMeta } from "/app/pages/dashboard/admin/AdminPendingProviders.vue?macro=true";
import { default as ProviderBasicInfodgfs9Vm2km7_gAgfU1cfaJtakYENlS6B9we6b8Gdx4YMeta } from "/app/pages/dashboard/providers/ProviderBasicInfo.vue?macro=true";
import { default as ProviderPriceInfoAuxs1rOCtsQ4HtmCP_45CSAPWTy1La9G4lboq2j0FMfWcMeta } from "/app/pages/dashboard/providers/ProviderPriceInfo.vue?macro=true";
import { default as ProviderPhotosInfoT__45EAry1elX86OSgUe_w8VdIipdU2u_LI68KdZmyiPMMeta } from "/app/pages/dashboard/providers/ProviderPhotosInfo.vue?macro=true";
import { default as ProviderSocialInfo2o_P_jb2TztMfx4OBkcFaksKhixE_45Vd9oWP17q3VtowMeta } from "/app/pages/dashboard/providers/ProviderSocialInfo.vue?macro=true";
import { default as ProviderAddressInfoDnqFV00_45S9GBRs5mOk5EcfF25DfcNjdHMEI3Ju5BdFEMeta } from "/app/pages/dashboard/providers/ProviderAddressInfo.vue?macro=true";
import { default as ProviderContactInfoHlgCYGcmRrxS4oxAYGTX_45jQ85Pd2mwYG7vuvqHohQrEMeta } from "/app/pages/dashboard/providers/ProviderContactInfo.vue?macro=true";
import { default as SpaceCharacteristicsInfo5_Cq3ZJqj_ArTaHz7mWEaWk_45j7gsVA_p4AuzVXqxHOkMeta } from "/app/pages/dashboard/spaces/SpaceCharacteristicsInfo.vue?macro=true";
import { default as SpaceCancellationPolicyInfo_45kscn9uiU3rRCFDnRdyo0xLpXIg1CGvKzhE2OKc98UUMeta } from "/app/pages/dashboard/spaces/SpaceCancellationPolicyInfo.vue?macro=true";
export default [
  {
    name: "blog",
    path: "/blog",
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "site",
    path: "/site",
    component: () => import("/app/pages/site/index.vue")
  },
  {
    name: "blog-article",
    path: "/blog/article",
    component: () => import("/app/pages/blog/article.vue")
  },
  {
    name: "site-contact",
    path: "/site/contact",
    component: () => import("/app/pages/site/contact.vue")
  },
  {
    name: "site-privacy",
    path: "/site/privacy",
    component: () => import("/app/pages/site/privacy.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/app/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    component: () => import("/app/pages/dashboard/quotes.vue")
  },
  {
    name: "dashboard-reviews",
    path: "/dashboard/reviews",
    component: () => import("/app/pages/dashboard/reviews.vue")
  },
  {
    name: "dashboard-settings",
    path: "/dashboard/settings",
    component: () => import("/app/pages/dashboard/settings.vue")
  },
  {
    name: "site-spaces-search",
    path: "/site/spaces/search",
    component: () => import("/app/pages/site/spaces/search.vue")
  },
  {
    name: "dashboard-favorites",
    path: "/dashboard/favorites",
    component: () => import("/app/pages/dashboard/favorites.vue")
  },
  {
    name: "site-fbdatadeletion",
    path: "/site/fbdatadeletion",
    component: () => import("/app/pages/site/fbdatadeletion.vue")
  },
  {
    name: "site-spaces-details",
    path: "/site/spaces/details",
    component: () => import("/app/pages/site/spaces/details.vue")
  },
  {
    name: "authentication-login",
    path: "/authentication/login",
    component: () => import("/app/pages/authentication/login.vue")
  },
  {
    name: "dashboard-spaces-new",
    path: "/dashboard/spaces/new",
    component: () => import("/app/pages/dashboard/spaces/new.vue")
  },
  {
    name: "site-providers-search",
    path: "/site/providers/search",
    component: () => import("/app/pages/site/providers/search.vue")
  },
  {
    name: "site-providers-details",
    path: "/site/providers/details",
    component: () => import("/app/pages/site/providers/details.vue")
  },
  {
    name: "authentication-register",
    path: "/authentication/register",
    component: () => import("/app/pages/authentication/register.vue")
  },
  {
    name: "dashboard-providers-new",
    path: "/dashboard/providers/new",
    component: () => import("/app/pages/dashboard/providers/new.vue")
  },
  {
    name: "dashboard-user-UserHome",
    path: "/dashboard/user/UserHome",
    component: () => import("/app/pages/dashboard/user/UserHome.vue")
  },
  {
    name: "dashboard-admin-AdminHome",
    path: "/dashboard/admin/AdminHome",
    component: () => import("/app/pages/dashboard/admin/AdminHome.vue")
  },
  {
    name: "dashboard-user-UserQuotes",
    path: "/dashboard/user/UserQuotes",
    component: () => import("/app/pages/dashboard/user/UserQuotes.vue")
  },
  {
    name: "dashboard-spaces-OwnerHome",
    path: "/dashboard/spaces/OwnerHome",
    component: () => import("/app/pages/dashboard/spaces/OwnerHome.vue")
  },
  {
    name: "dashboard-user-UserReviews",
    path: "/dashboard/user/UserReviews",
    component: () => import("/app/pages/dashboard/user/UserReviews.vue")
  },
  {
    name: "authentication-new-password",
    path: "/authentication/new-password",
    component: () => import("/app/pages/authentication/new-password.vue")
  },
  {
    name: "site-landings-owner-landing",
    path: "/site/landings/owner-landing",
    component: () => import("/app/pages/site/landings/owner-landing.vue")
  },
  {
    name: "dashboard-spaces-OwnerQuotes",
    path: "/dashboard/spaces/OwnerQuotes",
    component: () => import("/app/pages/dashboard/spaces/OwnerQuotes.vue")
  },
  {
    name: "dashboard-user-UserFavorites",
    path: "/dashboard/user/UserFavorites",
    component: () => import("/app/pages/dashboard/user/UserFavorites.vue")
  },
  {
    name: "authentication-register-owner",
    path: "/authentication/register-owner",
    component: () => import("/app/pages/authentication/register-owner.vue")
  },
  {
    name: "dashboard-spaces-OwnerReviews",
    path: "/dashboard/spaces/OwnerReviews",
    component: () => import("/app/pages/dashboard/spaces/OwnerReviews.vue")
  },
  {
    name: "site-landings-service-landing",
    path: "/site/landings/service-landing",
    component: () => import("/app/pages/site/landings/service-landing.vue")
  },
  {
    name: "authentication-register-service",
    path: "/authentication/register-service",
    component: () => import("/app/pages/authentication/register-service.vue")
  },
  {
    name: "dashboard-spaces-SpaceBasicInfo",
    path: "/dashboard/spaces/SpaceBasicInfo",
    component: () => import("/app/pages/dashboard/spaces/SpaceBasicInfo.vue")
  },
  {
    name: "dashboard-spaces-SpacePriceInfo",
    path: "/dashboard/spaces/SpacePriceInfo",
    component: () => import("/app/pages/dashboard/spaces/SpacePriceInfo.vue")
  },
  {
    name: "dashboard-spaces-SpaceRulesInfo",
    path: "/dashboard/spaces/SpaceRulesInfo",
    component: () => import("/app/pages/dashboard/spaces/SpaceRulesInfo.vue")
  },
  {
    name: "authentication-password-recovery",
    path: "/authentication/password-recovery",
    component: () => import("/app/pages/authentication/password-recovery.vue")
  },
  {
    name: "dashboard-providers-ProviderFaqs",
    path: "/dashboard/providers/ProviderFaqs",
    component: () => import("/app/pages/dashboard/providers/ProviderFaqs.vue")
  },
  {
    name: "dashboard-providers-ProviderHome",
    path: "/dashboard/providers/ProviderHome",
    component: () => import("/app/pages/dashboard/providers/ProviderHome.vue")
  },
  {
    name: "dashboard-spaces-SpacePhotosInfo",
    path: "/dashboard/spaces/SpacePhotosInfo",
    component: () => import("/app/pages/dashboard/spaces/SpacePhotosInfo.vue")
  },
  {
    name: "dashboard-spaces-SpaceAddressInfo",
    path: "/dashboard/spaces/SpaceAddressInfo",
    component: () => import("/app/pages/dashboard/spaces/SpaceAddressInfo.vue")
  },
  {
    name: "dashboard-spaces-SpaceContactInfo",
    path: "/dashboard/spaces/SpaceContactInfo",
    component: () => import("/app/pages/dashboard/spaces/SpaceContactInfo.vue")
  },
  {
    name: "dashboard-spaces-SpaceSocialsInfo",
    path: "/dashboard/spaces/SpaceSocialsInfo",
    component: () => import("/app/pages/dashboard/spaces/SpaceSocialsInfo.vue")
  },
  {
    name: "dashboard-admin-AdminPendingSpaces",
    path: "/dashboard/admin/AdminPendingSpaces",
    component: () => import("/app/pages/dashboard/admin/AdminPendingSpaces.vue")
  },
  {
    name: "dashboard-providers-ProviderQuotes",
    path: "/dashboard/providers/ProviderQuotes",
    component: () => import("/app/pages/dashboard/providers/ProviderQuotes.vue")
  },
  {
    name: "dashboard-spaces-SpaceServicesInfo",
    path: "/dashboard/spaces/SpaceServicesInfo",
    component: () => import("/app/pages/dashboard/spaces/SpaceServicesInfo.vue")
  },
  {
    name: "dashboard-providers-ProviderReviews",
    path: "/dashboard/providers/ProviderReviews",
    component: () => import("/app/pages/dashboard/providers/ProviderReviews.vue")
  },
  {
    name: "authentication-space-claim-ownership",
    path: "/authentication/space-claim-ownership",
    component: () => import("/app/pages/authentication/space-claim-ownership.vue")
  },
  {
    name: "dashboard-providers-ProviderTypeInfo",
    path: "/dashboard/providers/ProviderTypeInfo",
    component: () => import("/app/pages/dashboard/providers/ProviderTypeInfo.vue")
  },
  {
    name: "dashboard-spaces-SpaceEventTypesInfo",
    path: "/dashboard/spaces/SpaceEventTypesInfo",
    component: () => import("/app/pages/dashboard/spaces/SpaceEventTypesInfo.vue")
  },
  {
    name: "dashboard-admin-AdminPendingProviders",
    path: "/dashboard/admin/AdminPendingProviders",
    component: () => import("/app/pages/dashboard/admin/AdminPendingProviders.vue")
  },
  {
    name: "dashboard-providers-ProviderBasicInfo",
    path: "/dashboard/providers/ProviderBasicInfo",
    component: () => import("/app/pages/dashboard/providers/ProviderBasicInfo.vue")
  },
  {
    name: "dashboard-providers-ProviderPriceInfo",
    path: "/dashboard/providers/ProviderPriceInfo",
    component: () => import("/app/pages/dashboard/providers/ProviderPriceInfo.vue")
  },
  {
    name: "dashboard-providers-ProviderPhotosInfo",
    path: "/dashboard/providers/ProviderPhotosInfo",
    component: () => import("/app/pages/dashboard/providers/ProviderPhotosInfo.vue")
  },
  {
    name: "dashboard-providers-ProviderSocialInfo",
    path: "/dashboard/providers/ProviderSocialInfo",
    component: () => import("/app/pages/dashboard/providers/ProviderSocialInfo.vue")
  },
  {
    name: "dashboard-providers-ProviderAddressInfo",
    path: "/dashboard/providers/ProviderAddressInfo",
    component: () => import("/app/pages/dashboard/providers/ProviderAddressInfo.vue")
  },
  {
    name: "dashboard-providers-ProviderContactInfo",
    path: "/dashboard/providers/ProviderContactInfo",
    component: () => import("/app/pages/dashboard/providers/ProviderContactInfo.vue")
  },
  {
    name: "dashboard-spaces-SpaceCharacteristicsInfo",
    path: "/dashboard/spaces/SpaceCharacteristicsInfo",
    component: () => import("/app/pages/dashboard/spaces/SpaceCharacteristicsInfo.vue")
  },
  {
    name: "dashboard-spaces-SpaceCancellationPolicyInfo",
    path: "/dashboard/spaces/SpaceCancellationPolicyInfo",
    component: () => import("/app/pages/dashboard/spaces/SpaceCancellationPolicyInfo.vue")
  }
]